.intro-text-area {
  text-align: left;
}

.site-intro, .home-portfolio-summary {
  margin: 1.5rem;
  text-align: left;
}

h2 {
  text-align: left;
  margin: 1rem;
}

@media only screen and (min-width: 1025px) {
  h1 {
    margin: 1rem 10rem;
  }

  h2 {
    margin: 1rem 10rem;
    text-align: left;
  }

  .site-intro {
    padding-bottom: 1rem;
    margin: 1rem 10rem;
  }

  .site-intro h2 {
    margin: 0;
  }

  .home-portfolio-summary {
    text-align: left;
    margin: 1rem 10rem;
  }
}

@media only screen and (min-width: 641px) and (max-width: 800px) {
  body {
    font-size: 1.5rem;
  }

  h1 {
    margin: 1rem 5rem;
  }

  h2 {
    margin: 1rem 5rem;
    text-align: left;
  }

  .site-intro {
    padding-bottom: 1rem;
    margin: 1rem 5rem;
  }

  .site-intro h2 {
    margin: 0;
  }

  .home-portfolio-summary {
    text-align: left;
    margin: 1rem 5rem;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1024px) {

  body {
    font-size: 1rem;
  }

  h1 {
    margin: 1rem 5rem;
  }

  h2 {
    margin: 1rem 5rem;
    text-align: left;
  }

  .site-intro {
    padding-bottom: 1rem;
    margin: 1rem 5rem;
  }

  .site-intro h2 {
    margin: 0;
  }

  .home-portfolio-summary {
    text-align: left;
    margin: 1rem 5rem;
  }
}
