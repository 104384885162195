.about-page {
  height: 73.3vh;
}

.about-text {
  margin: 2rem 10rem;
  text-align: left;
}

@media only screen and (max-width: 640px) {
  .about-page {
    height: 79.2vh;
  }

  .about-text {
    margin: 1rem 2rem;
  }
}

@media only screen and (min-width: 641px) and (max-width: 800px) {
  .about-page {
    height: 74.8vh;
  }

  .about-text {
    margin: 1rem 5rem;
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1024px) {
  .about-page {
    height: 68.5vh;
  }
}
