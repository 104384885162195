:root {
    /*fonts*/
    font-size: 14px;

    /*color settings*/
    --bg-color: rgba(255,255,255, 1);
    --text-color: rgba(24,33,43, 1);
    --text-soft-color: rgba(28,33,38, 1);
    --text-strong-color: rgba(74,74,74, 1);
    --subtle-color: rgba(247,250,253, 1);
    --border-color: rgba(122,138,156, 1);
    --shadow-color: rgba(134,159,186, 1);
    --input-color: rgba(255,255,255, 1);
    --outline-color: rgba(101,175,237, 1);
    --mark-color: rgba(33,91,132, 0.16);
    --special-color: rgba(33,91,132, 1);
    --special-bg-color: rgba(34,94,136, 1);
    --special-text-color: rgba(255,255,255, 1);
    --special-shadow-color: rgba(10,17,25, 1);
    --special-mark-color: rgba(255,255,255, 0.16);
    --light-color: rgba(237,244,254, 1);
    --dark-color: rgba(52,73,93, 1);

    --nordgray: #4C566A;
    --nordyellow: #EBCB8B;
    --nordred: #BF616A;
    --nordgreen: #A3BE8C;
}

body {
    background-color: var(--bg-color);
    color: var(--text-color);
}

.main {
    margin: 0 10rem;
    height: 100%;
}

.content {
    margin: 0 3rem;
    padding: 1rem 5rem;
    box-shadow: 1rem 0.25rem 2rem var(--nordgray);
    z-index: 1;
}

@media only screen and (max-width: 640px) {
    :root {
        font-size: 12px;
    }

    .main, .content {
        padding: 0;
        margin: 0;
    }

    .content {
        box-shadow: none;
    }
}

@media only screen and (min-width: 641px) and (max-width: 800px) {
    .main, .content {
        padding: 0;
        margin: 0;
    }

    .content {
        box-shadow: none;
    }
}

@media only screen and (min-width: 801px) and (max-width: 1024px) {
    .main, .content {
        padding: 0;
        margin: 0;
    }

    .content {
        box-shadow: none;
    }
}
