@import "base.css";

.navbar {
  display: flex;
  flex-direction: row;
  padding: 2rem 9rem;
  margin: 0 3rem;
  background-color: var(--dark-color);
  box-shadow: 1rem 0.25rem 2rem var(--nordgray);
  font-size: 2rem;
  z-index: -1;
}

.navbar a {
  color: var(--special-text-color);
  text-decoration: none;
}

.brand {
  position: relative;
  margin-right: auto;
}


.nav-link {
  margin-right: 2rem;
  margin-left: 2rem;
}

@media only screen and (max-width: 640px) {
  .navbar {
    margin: 0;
    padding: 1rem 1rem;
    font-size: 1.2rem;
    box-shadow: none;
  }

  .navbar a.activeRoute {
    transition: none;
  }

  .nav-link {
    margin-right: 1rem;
    margin-left: 0.5rem;
  }

}

@media only screen and (min-width: 641px) and (max-width: 800px) {
  .navbar {
    margin: 0;
    padding: 1rem 1rem;
    box-shadow: none;
  }

  .nav-link {
    margin-right: 1rem;
    margin-left: 0.5rem;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1024px) {
  .navbar {
    padding: 1.5rem;
    margin: 0;
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 1025px) {
  .navbar a.activeRoute {
    color: var(--special-mark-color);
    text-decoration: var(--subtle-color) underline;
    transition: ease-in .2s;
  }
}
