.project-area {
  padding: 1rem 10rem;
}

.project {
  display: grid;
  flex-direction: column;
  grid-template-columns: 2fr 2fr;
  column-gap: 3rem;
  margin-bottom: 3rem;
  padding: 2rem;
  text-align: left;
  background-color: var(--special-color);
  border-radius: 1rem;
  box-shadow: 0 0 1rem var(--special-shadow-color);
  color: var(--subtle-color);
}

.p-col-1 {
  display: grid;
  align-content: center;
  grid-column: 1 / span 1;
  grid-row: 1;
}

.project-title {
  padding-bottom: 1rem;
  font-size: 2rem;
  color: var(--nordyellow);
  text-decoration: underline;
}

.project-body {
  margin: 1rem 0;
}

.links {
  display: grid;
  flex-direction: row;
  grid-template-rows: 2fr;
  align-content: center;
  border-radius: 2rem;
}

.links a {
  padding: 0.5rem 0;
  color: var(--nordyellow);
  grid-row: 1;
}

.links a svg {
  width: 15%;
  height: auto;
}

.links .hidden-pad {
  display: none;
}

.p-col-2 {
  display: grid;
  align-content: center;
  grid-column: 2 / span 2;
  grid-row: 1;
}

img.project-media, .p-col-2 video
{
  width:90%;
  height: auto;
}

@media only screen and (max-width: 640px) {
  .project-area {
    padding: 0;
    margin: 1rem;
  }

  .project {
    flex-direction: column;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr;
    padding: 1rem 1rem 0;
    column-gap: 0;
    row-gap: 0;
  }

  .p-col-1 {
    align-content: flex-start;
    grid-row: 1 / span 2;
    grid-column: 1;
    padding: 0;
    margin: 0
  }

  .project-body {
    margin: 0;
    padding: 0;
  }

  .p-col-2 {
    align-content: center;
    grid-row: 3;
    grid-column: 1;
    padding: 0;
    margin: 1rem;
  }

}

@media only screen and (min-width: 1025px) {
  .project:hover {
    box-shadow: 1rem 2rem 4rem var(--special-shadow-color);
    transform: translateY(-0.25rem);
    z-index: 1;
    transition: ease-in .15s;
  }

  .project:not(:hover) {
    transform: translateY(0.25rem);
    z-index: 0;
    transition: ease-out .2s;
  }
}
