#siteFooter {
  position: relative;
  padding-bottom: 1rem;
  bottom: 0;
  margin: auto 3rem 0;
  background-color: var(--dark-color);
  box-shadow: 1rem 0.25rem 2rem var(--nordgray);
  color: var(--special-text-color);
  z-index: -1;
}

.footer-content {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.footer-col {
  display: table-cell;
}

.footer-list {
  list-style-type: none;
  padding-left: 0;
}

@media only screen and (max-width: 640px) {
  #siteFooter {
    margin: auto 0;
  }
}

@media only screen and (min-width: 641px) and (max-width: 800px) {
  #siteFooter {
    margin: auto 0;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1024px) {
  #siteFooter {
    margin: auto 0;
  }
}
